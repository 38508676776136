import { useCallback, useEffect, useState } from "react";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaPlus } from "react-icons/fa";
import { CommonButton } from "../../common-styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import {
  fetchMemberShipDetails,
  updateMemberShipDetails,
  requestPayout,
  fetchRequestedPayoutDetails,
} from "../../Redux/slices/settingSlice";
import CommonLoader from "../../Components/CommonLoader";
import { formatIndianNumber } from "../../Utils";
import { message } from "antd";

const Payouts = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const settings = useSelector((state: RootState) => state.setting);
  const user = useSelector((state: RootState) => state.user);
  const [upiId, setUpiId] = useState("");
  const [isAvailableUPI, setIsAvailableUPI] = useState(false);
  const [loading, setLoading] = useState(false);

  const getMemberDetails = useCallback(() => {
    dispatch(fetchMemberShipDetails(user.id));
    setUpiId("");
    dispatch(fetchRequestedPayoutDetails());
  }, [dispatch, user.id]); // Ensure user.id is a dependency

  const submitUPIId = () => {
    dispatch(updateMemberShipDetails({ upiId }));
    getMemberDetails();
  };

  const requestWithDraw = async () => {
    setLoading(true);
    if (
      user.id &&
      settings?.memberIncomeStatistic?.totalEarnings &&
      settings.memberIncomeStatistic.totalEarnings >=
        Number(process.env.REACT_APP_MIN_AMOUNT_RESERVED_ON_PAYOUT)
    ) {
      const payload = {
        creator: user.id,
        amount: settings.memberIncomeStatistic.totalEarnings,
        status: "Requested",
        upiId: upiId,
      };
      await dispatch(requestPayout(payload));
      setLoading(false);
    } else {
      message.loading(
        `To Request payout you should have minimum ${process.env.REACT_APP_MIN_AMOUNT_RESERVED_ON_PAYOUT} amount...`
      );
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      // Ensure user.id is available before fetching details
      if (user && user.id !== undefined) {
        console.log("User ID is available, fetching membership details...");
        await getMemberDetails(); // Wait for the member details to be fetched
      }
    };

    fetchDetails(); // Call the async function inside the useEffect
  }, [user.id, getMemberDetails, user]);

  useEffect(() => {
    if (settings && settings.memberDetails.upiId) {
      setIsAvailableUPI(true);
      setUpiId(settings.memberDetails.upiId);
    }
  }, [settings]);

  if (settings.loading)
    return (
      <S.Payouts>
        <h1 className="text-white text-center font-noto font-semibold">
          Payouts
        </h1>
        <CommonLoader message="fetching payouts details..." />
      </S.Payouts>
    );

  return (
    <S.Payouts className="text-white">
      <h1 className="text-white font-noto flex items-center gap-5 md:gap-10">
        <span onClick={() => navigate("/account")}>
          <FaArrowLeft
            className="cursor-pointer"
            onClick={() => navigate("/account")}
            size={30}
          />
        </span>
        <span className="font-semibold">Payouts</span>
      </h1>

      <div className="flex items-center justify-center flex-col">
        <p className="text-gray-400 text-2xl md:text-4xl sm:text-lg">
          Current Earnings
        </p>
        <p className="text-white text-2xl md:text-4xl sm:text-lg my-6">
          {settings?.memberIncomeStatistic?.totalEarnings
            ? settings.hadRequestedRequest
              ? // Calculate totalEarnings - totalPayouts - requestedPayoutAmount
                `₹ ${formatIndianNumber(
                  Number(settings.memberIncomeStatistic.totalEarnings) -
                    Number(settings.memberIncomeStatistic.totalPayouts) -
                    Number(settings.requestedPayoutAmount)
                )}`
              : // Calculate totalEarnings - totalPayouts (no requested payout)
                `₹ ${formatIndianNumber(
                  Number(settings.memberIncomeStatistic.totalEarnings) -
                    Number(settings.memberIncomeStatistic.totalPayouts)
                )}`
            : // Fallback for no earnings
              `₹ 0.00`}
        </p>
        <div className="md:w-[20rem] flex items-center justify-center flex-col">
          {settings.hadRequestedRequest && (
            <p className="my-5 text-gray-400">
              {`Request pending ${formatIndianNumber(
                Number(settings.requestedPayoutAmount)
              )}`}
            </p>
          )}
          <CommonButton
            variant={
              !isAvailableUPI || settings.hadRequestedRequest
                ? "primary"
                : "secondary"
            }
            onClick={() => requestWithDraw()}
            disabled={
              !isAvailableUPI || loading || settings.hadRequestedRequest
            }
          >
            {loading ? `Requesting...` : `Request Withdrawal`}
          </CommonButton>
        </div>
        <div className="my-6 md:w-[20rem] flex items-center flex-col">
          <S.CommonInput
            placeholder="Add your UPI ID"
            name="upiId"
            value={upiId}
            onChange={(e) => setUpiId(e.target.value)}
          />
          {upiId && (
            <div
              className="flex items-center underline underline-offset-2 cursor-pointer mt-2"
              onClick={() => submitUPIId()}
            >
              <FaPlus className="cursor-pointer" size={15} />{" "}
              {isAvailableUPI ? "Update" : "Add"}
            </div>
          )}
        </div>
        <div className="text-center text-gray-400 md:w-[30rem]">
          <p>
            You will receive 80-90% of your earnings after taxes and platform's
            fees.
          </p>
          <p>
            Most withdrawals are instant but it may take upto a week to process
            your withdrawal. You will get the complete details on your
            registered email address.
          </p>
        </div>
      </div>
    </S.Payouts>
  );
};

export default Payouts;
