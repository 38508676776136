import { FaArrowLeft } from "react-icons/fa";
import * as M from "./styled";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { RoleTypes } from "../../Constant/enums/roleTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExploreList,
  fetchMembershipList,
} from "../../Redux/slices/exploreSlice";
import { AppDispatch, RootState } from "../../Redux/store";
import UserMemberShip from "../../Components/Memberships";
import Suggesstion from "../../Components/Suggesstion";
import { ExploreList } from "../../Config/interface.config";
import { getUserDetails } from "../../Redux/slices/userSlice";
import { isCreatorRole } from "../../Config/common.config";
import SubscriptionModal from "../../Modals/SubscriptionModal";
import CommonLoader from "../../Components/CommonLoader";

const Membership = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const { loading, results } = useSelector((state: RootState) => state.explore);

  const [open, setOpen] = useState<boolean>(false);
  const [selectedCreator, setSelectedCreator] = useState<ExploreList | null>(
    null
  );

  const fetchCreatorsList = useCallback(
    (fullname?: string) => {
      let requestPayload = {
        limit: 1000,
        page: 0,
        isFilterBySubscriber: false,
        role: RoleTypes.CREATOR,
      } as {
        limit: number;
        page: number;
        isFilterBySubscriber?: boolean;
        role?: string;
        fullname?: string;
      };

      dispatch(fetchMembershipList(requestPayload)).then(
        ({ payload: data }) => {
          console.log("data data: ", data);
        }
      );
    },
    [dispatch]
  );

  const handler = async () => {
    await fetchCreatorsList();
  };

  const onSubscribe = async (creator: ExploreList) => {
    if (user?.id) {
      setSelectedCreator(creator);
      setOpen(true);
    } else {
      dispatch(getUserDetails());
    }
  };

  useEffect(() => {
    handler();
  }, []);

  if (loading)
    return (
      <M.MemberShip>
        <div className="px-[5px] sm:px-[2rem]">
          <CommonLoader message="fetching memberships..." />
        </div>
      </M.MemberShip>
    );

  return (
    <M.MemberShip>
      <div className="px-[5px] sm:px-[2rem]">
        <h1 className="text-white font-noto flex items-center gap-5 md:gap-10">
          <span className="cursor-pointer" onClick={() => navigate("/account")}>
            <FaArrowLeft
              className="cursor-pointer"
              onClick={() => navigate("/account")}
              size={30}
            />
          </span>
          <span className="text-[28px]">Membership</span>
        </h1>

        <div className="mt-[2rem] gap-10 flex flex-col">
          {!loading &&
            user &&
            user?.subscribeTo &&
            user?.subscribeTo.length > 0 &&
            user.subscribeTo.map((user, index) => (
              <div key={`exp-sgst-key-${index}`}>
                {<UserMemberShip creator={user} />}
              </div>
            ))}
        </div>
      </div>

      <div className="mt-10 px-[5px] sm:px-[2rem]">
        <h3 className="text-lg sm:text-2xl">Recommendation</h3>
        <div className="mt-5">
          {!loading &&
            results?.length > 0 &&
            results?.map((user, index) => (
              <div>
                {!user?.isSubscribed && (
                  <div className="mb-10" key={`exp-sgst-key-${index}`}>
                    <Suggesstion
                      creator={user}
                      subscribeCreator={onSubscribe}
                    />
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>

      {/* Buy subcription Modal */}
      {!isCreatorRole(user.role) && (
        <SubscriptionModal
          selectedCreator={selectedCreator}
          onHide={() => setOpen(false)}
          show={open}
        />
      )}
    </M.MemberShip>
  );
};

export default Membership;
