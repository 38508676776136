import styled, { css } from "styled-components";
import SlickSlider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";


const arrow_css = css`
  fill: #ffffff;
  width: 30px;
  height: 30px;

  @media screen and (max-width: 768px){
    width: 15px;
    height: 15px;
  }
`
export const LeftArrow = styled(FaChevronLeft)`${arrow_css}`
export const NextArrow = styled(FaChevronRight)`${arrow_css}`

export const Post = styled.div<{ $locked: boolean }>`
  /* width: 600px; */
  max-width: 100%;
  margin: auto;
  margin-bottom: 4rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  padding-bottom: 0;
  margin-bottom: 1rem;
`;

export const UserImage = styled.img`
  width: 50px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
  border: 1.5px solid #fff;
  background: white;
`;

export const UserTitle = styled.p`
  font-size: 1.1rem;
  color: white;
`;

export const UserName = styled(Link)`
  font-size: 14px;
  color: rgba(255, 255, 255, .7);
`;

export const Time = styled.p`
  color: #8b8b8b;
  font-size: 14px;
  margin-top: 0;
`;

export const Description = styled.div`
  color: #ffffff;
  font-size: 17px;
  margin: auto;
  margin-top: 0.4rem;
  margin-bottom: 1rem;
  span {
    font-weight: 500;
  }
  padding: 1rem;
`;

export const PostImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PostFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0 1rem;
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
`;

export const Slider = styled(SlickSlider)`
  .slick-dots {
    bottom: -30px !important;
    z-index: 0;
    li {
      width: 10px;
      height: 10px;
      margin: 0 3px;
    }

    li.slick-active {
      .slider-dot {
        background: #3897f0;
      }
    }
  }

  .slick-next {
    right: 10px !important;
  }

  .slick-prev {
    left: 10px !important;
    z-index: 10;
  }
`;

export const SliderContent = styled.div`
  aspect-ratio: 1/1;
  width: 100%;
  background-color: #1b1b1b;
  position: relative;

  .play-icon {
    fill: white;
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 200;
  }
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LikesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 0.5rem;
`;

export const LikedByImg = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
`;

export const LikedByText = styled.p`
  color: white;
  font-weight: 300;
  font-size: 14px;
  b {
    font-weight: 400;
    cursor: pointer;
  }
`;
export const LockedPostContainer = styled.div`
  width: 100%;
  height: 600px;
  position: relative;
  overflow: hidden;
`;

export const LockedPostImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(20px);
`;

export const UnlockPost = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  svg{
    stroke-width: .5px;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(1deg);
    position: absolute;
    width: 95%;
    height: 90%;
    pointer-events: none;
    user-select: none;
  }
`;
export const UnlockPostBtn = styled.button`
  background-color: rgb(255 255 255);
  padding: 0.4rem 1.2rem;
  color: black;
  border-radius: 50px;
  font-weight: 500;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SampleComment = styled.p`
  margin-top: 0.4rem;
  margin-bottom: 0;
  color: #727272;
  font-size: 14px;
  cursor: pointer;
`;

export const PostVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 100;
`;

export const ActionTools = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 600px){
    padding-right: 1rem;
  }
`