import { SubscribeToDetails, UserDetails } from "../../Config/interface.config";
import { CommonButton } from "../../common-styles";
import { ReactComponent as GalleryIcon } from "./gallery-icon.svg";
import { ReactComponent as LinkIcon } from "./LinkIcon.svg";
import DefaultUserImg from "../../Assets/Images/user-profile.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import * as D from "../UserDetails/style";
import WhiteTick from "../../Assets/Icons/WhiteTick";
import { copyUserProfileLink } from "../../Utils";
import { PiCrownSimpleLight } from "react-icons/pi";
import { checkIsSubscribe, DEFAULT_PRICING, isCreatorRole } from "../../Config/common.config";
import toast from "react-hot-toast";

interface Props {
  user: Partial<UserDetails>;
  isProfile?: boolean;
  subscribeCreator?: (creator: Partial<UserDetails>) => void;
  authenticateUser?: () => void;
}
const ProfileHeader = ({
  user,
  isProfile = false,
  subscribeCreator,
  authenticateUser,
}: Props) => {
  const navigate = useNavigate();
  const loggedInUser = useSelector((state: RootState) => state.user);
  console.log(loggedInUser)
  console.log(user)
  const { image, fullname, totalPost } = user;

  const onSubscribe = () => {
    if (!loggedInUser?.id) {
      toast(
        "To stay updated with all the latest content and support your favorite creator, you'll need to be logged in. If you haven't already, please log in or register to complete your subscription."
      );
      authenticateUser && authenticateUser();
    } else {
      subscribeCreator && subscribeCreator(user);
    }
  };

  return (
    <D.ProfileHeaderContainer>
      <div className="section-one">
        {user?.backgroundImage?.fileURL && (
          <D.ProfileBackgroundImage
            crossOrigin="anonymous"
            src={user?.backgroundImage?.fileURL}
          />
        )}
      </div>

      <div className="section-two">
        <div className="flex items-start justify-between mt-3">
          <div className="flex items-start sm:gap-5 gap-2">
            <D.ProfileImage
              className="relative bottom-[4rem]"
              crossOrigin="anonymous"
              src={image ? image.fileURL : DefaultUserImg}
            />
            <div className="mb-1">
              <div className="flex gap-1 sm:gap-4 items-center">
                <h1 className="text-white font-medium font-inter text-lg sm:text-3xl">
                  {fullname}
                </h1>

                <WhiteTick key={"white"} />
              </div>
              {user?.username && (
                <p className="text-white opacity-[67%] font-inter font-light">
                  @{user?.username}
                </p>
              )}
            </div>
          </div>
          <div className="hidden gap-5 sm:flex">
            <div className="flex gap-2">
              <GalleryIcon />
              <span className="text-white count-span">{totalPost}</span>
            </div>

            {/* <div className="flex gap-2">
              <LockIcon />
              <span className="text-white count-span">0</span>
            </div> */}
          </div>
        </div>
      </div>

      <div className="section-three">
        {user.bio && <p className="text-white">{user.bio}</p>}
        {!isCreatorRole(loggedInUser?.role) && isCreatorRole(user.role) && !checkIsSubscribe(user.id as string, loggedInUser.subscribeTo as SubscribeToDetails[]) && (
          <div className="flex items-center justify-center">
            <D.JoinBtn onClick={() => onSubscribe()}>
              <PiCrownSimpleLight size={20} strokeWidth={"10px"} />
              Join For ₹{user?.memberDetails?.monthlyPrice ?? DEFAULT_PRICING}
            </D.JoinBtn>
          </div>
        )}
        {loggedInUser?.id && user?.username && (
          <div
            className="cursor-pointer flex items-center gap-2 mt-5"
            onClick={() => copyUserProfileLink(user?.username ?? "")}
          >
            <LinkIcon className="text-white cursor-pointer" />
            <span className="text-[#319DFF] text-sm sm:text-lg break-words w-full">
              {window.location.origin}/profile/{user?.username}
            </span>
          </div>
        )}
        {!isProfile && (
          <div className="flex items-center flex-col sm:flex-row gap-4 justify-between mx-6 mt-5">
            <CommonButton
              variant="dark"
              className="border-2 border-white w-full text-base sm:text-lg font-medium"
              onClick={() => navigate("/profile/edit")}
            >
              Edit Profile
            </CommonButton>

            <CommonButton
              onClick={() => navigate(`/profile/${user.username}`)}
              variant="dark"
              className="border-2 border-white w-full text-base sm:text-lg font-medium"
            >
              Preview Profile
            </CommonButton>
          </div>
        )}
      </div>
    </D.ProfileHeaderContainer>
  );
};

export default ProfileHeader;
