import React, { useEffect, useState } from "react";
import Post from "../../../Components/Post/Post";
import { getToken, isCreatorRole } from "../../../Config/common.config";
import * as H from "../../Home/style";
import { useParams } from "react-router-dom";
import ProfileHeader from "../../../Components/Profile/ProfileHeader";
import { fetchUserByUserName } from "../../../Utils";
import { UserPublicProfile } from "./interface";
import CommonLoader from "../../../Components/CommonLoader";
import { useDispatch, useSelector } from "react-redux";
import { viewProfilePage } from "../../../Redux/slices/userSlice";
import { UserDetails } from "../../../Config/interface.config";
import SubscriptionModal from "../../../Modals/SubscriptionModal";
import SignUpModal from "../../../Modals/SignupModal";
import { RootState } from "../../../Redux/store";

const initialState: UserPublicProfile = {
  fullname: "",
  email: "",
  bio: "",
  role: "",
  isApproved: false,
  isDeleted: false,
  subscribeTo: [],
  createdAt: "",
  updatedAt: "",
  socialLinks: {
    username: "",
    instagram: "",
    youtube: "",
    threads: "",
    others: "",
  },
  username: "",
  image: {
    name: "",
    id: "",
    fileURL: "",
  },
  backgroundImage: {
    name: "",
    id: "",
    fileURL: "",
  },
  id: "",
  posts: [],
  roleType: ""
};

const ViewProfile: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const { username } = useParams();
  const [profile, setProfile] = useState<UserPublicProfile>(initialState);
  const [message, setMesssage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openAuth, setOpenAuth] = useState<boolean>(false);

  useEffect(() => {
    const token = getToken();
    dispatch(viewProfilePage(!!token));
  }, []);

  const { role } = profile;

  const fetchUser = async () => {
    try {
      setLoading(true);
      if (typeof username !== "string") return;

      const profile: UserPublicProfile = await fetchUserByUserName(username);
      setLoading(false);

      if (profile) setProfile(profile);
      else setMesssage("No such profile...");
    } catch (error: any) {
      console.log("error: ", error.response.data);
    }
  };

  const onSubscribe = async (creator: Partial<UserDetails>) => {
    if (creator?.id) {
      setOpen(true);
    }
  };

  const onAuthUser = async () => {
    setOpenAuth(true)
  }

  useEffect(() => {
    if (!username) return;

    fetchUser();
  }, [username]);

  if (loading)
    return (
      <H.HomeWrapper className="mx-auto">
        <div className="w-full">
          <CommonLoader message="Fetching creator details" />;
        </div>
      </H.HomeWrapper>
    );

  return (
    <H.HomeWrapper>
      <H.Feed className="feed">
        {message && (
          <div className="my-20">
            <p className="text-xl text-white text-center">{message}</p>
          </div>
        )}

        <H.FeedWrapper stretch={isCreatorRole(role)}>
          <div>
            {isCreatorRole(role) && (
              <ProfileHeader
                isProfile={true}
                user={{
                  loading,
                  totalPost: profile.posts.length,
                  backgroundImage: profile.backgroundImage,
                  image: profile.image,
                  bio: profile.bio,
                  username: profile.username,
                  fullname: profile.fullname,
                  id: profile.id,
                  role: profile.role
                }}
                subscribeCreator={onSubscribe}
                authenticateUser={onAuthUser}
              />
            )}

            <div className="feed-box">
              {profile.posts?.map((post, index) => (
                <Post
                  key={`post-key-${index}`}
                  post={post}
                  locked={!getToken()}
                />
              ))}
            </div>
          </div>
        </H.FeedWrapper>
      </H.Feed>
      {/* Buy subcription Modal */}
      {
        <SubscriptionModal
          selectedCreator={profile}
          onHide={() => setOpen(false)}
          show={open}
        />
      }
      {/* Auth user Modal */}
      {
        <SignUpModal
          onHide={() => setOpenAuth(false)}
          show={openAuth}
        />
      }
    </H.HomeWrapper>
  );
};

export default ViewProfile;
