import * as P from "./style";
import { FeedPosts } from "../../Config/interface.config";
import { isFileVideo } from "../../Config/common.config";
import VideoPlayer from "./VideoHandler";
import { ReactComponent as UnlockPostIcon } from "./UnlockPostIcon.svg";

interface Props {
  locked?: boolean;
  post: FeedPosts;
}

const PostSlider = ({ locked = false, post }: Props) => {
  const settings = {
    dots: !locked,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    appendDots: (dots: any) => <ul>{dots}</ul>,
    customPaging: (i: number) => <P.Dot className="slider-dot"></P.Dot>,
  };

  if (!locked)
    return (
      <>
        {post?.files?.length > 1 ? (
          <P.Slider
            {...settings}
            prevArrow={<P.LeftArrow />}
            nextArrow={<P.NextArrow />}
          >

            {post?.files?.map((file, _index) => (
              <P.SliderContent key={`file-${file.id}`}>
                {!isFileVideo(post?.files[_index].name) ? (
                  <P.PostImage crossOrigin="anonymous" src={file.fileURL} />
                ) : (
                  <VideoPlayer url={file.fileURL} key="main-video-player" />
                )}
              </P.SliderContent>
            ))}
          </P.Slider>
        ) : (
          <div>
            <>
              {post?.files && post?.files?.length === 1 && (
                <P.SliderContent>
                  {!isFileVideo(post?.files[0].name) ? (
                    <P.PostImage
                      crossOrigin="anonymous"
                      src={post?.files[0].fileURL}
                    />
                  ) : (
                    <VideoPlayer
                      url={post?.files[0].fileURL}
                      key="main-video-player"
                    />
                  )}
                </P.SliderContent>
              )}
            </>
          </div>
        )}
      </>
    );

  return (
    <P.LockedPostContainer>
      <P.LockedPostImg className="locked-img" crossOrigin="anonymous" src={post?.files[0]?.fileURL} />
      <P.UnlockPost>
        <UnlockPostIcon />
        <P.UnlockPostBtn className="font-roboto">Become a member to unlock</P.UnlockPostBtn>
      </P.UnlockPost>
    </P.LockedPostContainer>
  );
};

export default PostSlider;
